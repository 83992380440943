@import url("https://fonts.googleapis.com/css2?family=Raleway:wght@400;700&display=swap");

#home {
  position: relative;
  background-size: cover;
  background-position: center;
  background-repeat: repeat;
  min-height: 100vh;

  .app__wrapper {
    padding: 0;

    .copyright {
      display: none;
    }
  }
}

.app__header {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  width: 100%;
  position: relative;
  padding: 6rem 2rem;
  z-index: 0;
  flex-direction: column;

  @media screen and (min-width: 2000px) {
    padding-top: 8rem;
  }

  @media screen and (max-width: 1200px) {
    flex-direction: column;
  }

  @media screen and (max-width: 768px) {
    padding: 4rem 1rem;
  }

  @media screen and (max-width: 450px) {
    padding: 3rem 1rem;
  }
}

.circle-cmp {
  z-index: 10;
  position: absolute;
  transition: transform 0.2s ease-out;

  img {
    width: 80px;
    height: 80px;

    @media screen and (max-width: 1200px) {
      width: 70px;
      height: 70px;
    }

    @media screen and (max-width: 768px) {
      width: 55px;
      height: 55px;
    }

    @media screen and (max-width: 450px) {
      width: 45px;
      height: 45px;
    }
  }
}

.app__center-component {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  z-index: 5;
  margin: 0 auto;

  .app__img {
    margin-bottom: 20px;

    img {
      width: 300px;
      height: 100px;
      object-fit: contain;

      @media screen and (max-width: 1200px) {
        width: 250px;
        height: 80px;
      }

      @media screen and (max-width: 768px) {
        width: 200px;
        height: 70px;
      }

      @media screen and (max-width: 450px) {
        width: 100%;
        height: 60px;
      }
    }
  }

  .app__text {
    font-family: "Raleway";
    font-size: 1.75rem;
    color: #1f5284;
    max-width: 500px;

    @media screen and (max-width: 1200px) {
      font-size: 1.5rem;
      max-width: 400px;
    }

    @media screen and (max-width: 768px) {
      font-size: 1.25rem;
      max-width: 350px;
    }

    @media screen and (max-width: 450px) {
      font-size: 1.5rem;
      max-width: 300px;
    }
  }
}

.app__header-circles-left,
.app__header-circles {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  width: 100%;
  height: 100%;
  pointer-events: none;
  z-index: 5;
}

.app__header-circles-left div,
.app__header-circles div {
  position: absolute;
}

/* Image positions */
.app__header-circles-left div:nth-child(1) {
  top: 20%;
  left: 15%;
}

.app__header-circles-left div:nth-child(2) {
  top: 50%;
  left: 25%;
}

.app__header-circles-left div:nth-child(3) {
  top: 80%;
  left: 10%;
}

.app__header-circles div:nth-child(1) {
  top: 20%;
  right: 15%;
}

.app__header-circles div:nth-child(2) {
  top: 50%;
  right: 25%;
}

.app__header-circles div:nth-child(3) {
  top: 80%;
  right: 10%;
}

@media screen and (max-width: 1400px) {
  .app__header-circles-left div:nth-child(2),
  .app__header-circles div:nth-child(2) {
    top: 60%;
  }
}

@media screen and (max-width: 1200px) {
  .app__header-circles-left div:nth-child(1),
  .app__header-circles div:nth-child(1) {
    top: 25%;
  }

  // .app__header-circles-left div:nth-child(2),
  // .app__header-circles div:nth-child(2) {
  //   top: 50%;
  // }

  .app__header-circles-left div:nth-child(3),
  .app__header-circles div:nth-child(3) {
    top: 75%;
  }

  .circle-cmp img {
    width: 70px;
    height: 70px;
  }
}

@media screen and (max-width: 768px) {
  .app__header-circles-left div:nth-child(1),
  .app__header-circles div:nth-child(1) {
    top: 30%;
  }

  .app__header-circles-left div:nth-child(2),
  .app__header-circles div:nth-child(2) {
    top: 65%;
  }

  .app__header-circles-left div:nth-child(3),
  .app__header-circles div:nth-child(3) {
    top: 80%;
  }

  .circle-cmp img {
    width: 55px;
    height: 55px;
  }
}

@media screen and (max-width: 450px) {
  .app__header-circles-left div:nth-child(1),
  .app__header-circles div:nth-child(1) {
    top: 35%;
  }

  .app__header-circles-left div:nth-child(2),
  .app__header-circles div:nth-child(2) {
    top: 60%;
  }

  .app__header-circles-left div:nth-child(3),
  .app__header-circles div:nth-child(3) {
    top: 85%;
  }

  .circle-cmp img {
    width: 45px;
    height: 45px;
  }

  .app__header-circles-left,
  .app__header-circles {
    display: none;
  }
}
