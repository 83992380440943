@import url("https://fonts.googleapis.com/css2?family=Raleway:wght@400;700&display=swap");

body {
  font-family: "Raleway", sans-serif;
}

.app__about {
  flex: 1;
  width: 100%;
  flex-direction: column;
}

.head-text {
  font-family: "Raleway";
}

.app__profiles {
  display: flex;
  justify-content: center;
  align-items: flex-start;
  flex-wrap: wrap;
  margin-top: 2rem;
}

/* Profile Item */
.app__profile-item {
  width: 190px;
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  flex-direction: column;
  margin: 2rem;

  img {
    width: 100%;
    height: 170px;
    border-radius: 15px;
    object-fit: cover;
  }

  /* Large screens */
  @media screen and (min-width: 2000px) {
    width: 370px;
    margin: 2rem 4rem;

    img {
      height: 320px;
    }
  }

  /* Medium screens (desktops and tablets) */
  @media screen and (max-width: 1200px) {
    width: 250px;
    margin: 1.5rem 3rem;

    img {
      height: 220px;
    }
  }

  /* Tablets and smaller laptops */
  @media screen and (max-width: 768px) {
    width: 200px;
    margin: 1rem 2rem;

    img {
      height: 180px;
    }
  }

  /* Small mobile devices */
  @media screen and (max-width: 450px) {
    width: 150px;
    margin: 1rem;

    img {
      height: 140px;
    }
  }

  /* Extra small mobile devices */
  @media screen and (max-width: 350px) {
    width: 120px;
    margin: 0.75rem;

    img {
      height: 110px;
    }
  }
}
